body {
  padding: 32px;
}

.controls {
  margin-bottom: 32px;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 32px;
}
th {
  background-color: #eee;
  font-weight: bold;
}
th,
td {
  border: 0.125em solid #333;
  line-height: 1.5;
  padding: 0.75em;
  text-align: left;
}
/* Stack rows vertically on small screens */
@media (max-width: 30em) {
  /* Hide column labels */
  thead tr {
    position: absolute;
    top: -9999em;
    left: -9999em;
  }
  tr {
    border: 0.125em solid #333;
    border-bottom: 0;
  }
  /* Leave a space between table rows */
  tr + tr {
    margin-top: 1.5em;
  }
  /* Get table cells to act like rows */
  tr,
  td {
    display: block;
  }
  td {
    border: none;
    border-bottom: 0.125em solid #333;
  /* Leave a space for data labels */
    padding-left: 50%;
  }
  /* Add data labels */
  td:before {
    content: attr(data-label);
    display: inline-block;
    font-weight: bold;
    line-height: 1.5;
    margin-left: -100%;
    width: 100%;
  }
}

.header {
  background: black;
  color: white;
  border: 0.125em solid white;
}
.all {
  background: #777;
  color: white;
  border: 0.125em solid white;
}

.split-cell {
  width: 100px;
  height: 50px;
  padding: 0;
  margin: 0;
}

.line {
  width: 112px;
  height: 47px;
  border-bottom: 2px solid white;
  transform: translateY(-20px) translateX(5px) rotate(27deg);
  position: absolute;
  /* z-index: -1; */
}

.split-cell>div {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.bottom {
  position: absolute;
  bottom: 1px;
  left: 1px;
}

.top {
  position: absolute;
  top: 1px;
  right: 1px;
}